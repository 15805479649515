article.virtual-workshop{

	margin-top: 50px;
	
	.event-content{
		border:none;
	}
	.event-title{
		text-align: center;
	    font-weight: bold;
	    text-transform: uppercase;
	        line-height: 50px;
    letter-spacing: 3px
	}

	.intro-text {
	    font-size: 21px;
	    margin-bottom: 60px;
	}
	
	.register-con-box {
	    border: 1px solid #d4d4d4;
	    padding: 60px 80px;
	    margin-bottom: 60px;
	    -webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		border-radius: 10px;

		h3 {
		    font-size: 24px;
		    font-style: italic;
		    text-align: center;
		    line-height: 35px;
		    margin-bottom: 90px;
		    margin-top: 60px;
		}

		.cta-2 {
		    background: #00cfff;
		    color: #fff;
		    padding: 15px 0;
		    position: relative;
		    margin-right: 60px;

		    h3{
		    	font-weight: bold;
			    text-transform: uppercase;
			    font-style: normal;
			    font-size: 20px;
			    letter-spacing: 1px;
			    text-align: right;
			    margin: 0;
			    line-height: 16px;
		    }
		    span{
		    	font-size: 18px;
			    color: #000;
			    text-align: right;
			    display: block;
		    }
		    #arrow-right{
		    	width: 0px;
			    height: 0px;
			    border-top: 50px solid transparent;
			    border-bottom: 50px solid transparent;
			    border-left: 50px solid #00cfff;
			    position: absolute;
			    right: -50px;
			    top: -14px;
		    }
		}

		.register-box {
		    padding: 50px 60px;
		    background: #EEEEEE;
		    -webkit-border-radius: 5px;
		    -moz-border-radius: 5px;
		    border-radius: 5px;
		    text-align: center;

		    h2{
		    	text-transform: uppercase;
			    font-weight: 700;
			    letter-spacing: 2px;
			    font-size: 36px;
			    margin-top: 0;
		    }
		    #event-day {
			    font-size: 18px;
			    font-weight: bold;
			    display: block;
			}
			#event-time {
			    display: block;
			    margin-top: 30px;
			    font-size: 21px;
			}
			a.register-btn {
			    background: #2162A8;
			    color: #fff;
			    font-size: 21px;
			    margin-top: 35px;
			    display: block;
			    padding: 20px 10px;
			    text-transform: uppercase;
			    font-weight: bold;
			    letter-spacing: 2px;
			    text-decoration: none;
			    -webkit-border-radius: 5px;
			    -moz-border-radius: 5px;
			    border-radius: 5px;
			}
		}
	}

	#event-details {
	    margin-bottom: 50px;

	    p {
		    color: #000;
		    font-size: 21px;
	    }
	}

	#cta-3 {
	    background: #00cfff;
	    color: #fff;
	    font-size: 18px;
	    padding: 40px 20px;
    	margin-bottom: 70px;

	    p{
	    	margin:0;
	    }
	}
	#topics {
	    background: #FBFBFB;
	    padding: 80px 0;

	    h3{
	        font-size: 34px;
    		color: #0B62A8;
    		margin-bottom: 50px;
	    }

	    .topic{
	    	margin-bottom: 40px;

	    	.topic-count {
			    font-size: 34px;
			    color: #0B62A8;
			    position: absolute;
			    font-weight: bold;
			}
			p{
				font-size: 21px;
			    display: inline-block;
			    margin-left: 35px;
			    line-height: 22px;
			    margin-top: 7px;
			    margin-bottom: 0;
			}
	    }
	    
	}

	#cta-4{
		margin-top: 100px;

		h4{
			font-size: 34px;
		}
		p{
			font-size: 21px;
    		margin-top: 15px;
		}
		.register-btn{
			background: #2162a8;
		    color: #fff;
		    font-size: 21px;
		    margin-top: 35px;
		    display: inline-block;
		    padding: 20px 40px;
		    text-transform: uppercase;
		    font-weight: 700;
		    letter-spacing: 2px;
		    text-decoration: none;
		    -webkit-border-radius: 5px;
		    -moz-border-radius: 5px;
		    border-radius: 5px;
		}
	}
}

@media (max-width: 1199px) { 
   article.virtual-workshop .register-con-box .cta-2 h3 {
   	font-size: 15px;
   }
   article.virtual-workshop .register-con-box .cta-2 span {
   	font-size: 15px;
   }
   article.virtual-workshop .register-con-box .register-box h2 {
   	font-size: 30px;
   }
   article.virtual-workshop .register-con-box .register-box a.register-btn {
   	font-size: 18px;
   }
   article.virtual-workshop #topics h3 {
   	font-size: 30px;
   }
   article.virtual-workshop .event-title {
   	font-size: 30px;
   	line-height: 40px;
   }
   article.virtual-workshop .intro-text {
   	font-size: 20px;
   }
   article.virtual-workshop .register-con-box h3 {
   	font-size: 22px;
   	line-height: 30px;
   }
   article.virtual-workshop .register-con-box .register-box #event-time {
   	font-size: 20px;
   }
   article.virtual-workshop #event-details p {
   	font-size: 20px;
   }
   article.virtual-workshop #topics .topic .topic-count {
   	font-size: 30px;
   }
   article.virtual-workshop #topics .topic p {
   	font-size: 20px;
   }
   article.virtual-workshop #cta-4 h4 {
   	font-size: 30px;
   }
   article.virtual-workshop #cta-4 p {
   	font-size: 20px;
   }
   article.virtual-workshop #cta-4 .register-btn {
   	font-size: 18px;
   }
   article.virtual-workshop .register-con-box .cta-2 #arrow-right {
   	top: -16px;
   }
}
@media (max-width: 1080px) {
	article.virtual-workshop .register-con-box{
		padding: 60px 20px;
	}
}
@media (max-width: 991px) {
   article.virtual-workshop .register-con-box .cta-2 #arrow-right {
   	top: -18px;
   }
   article.virtual-workshop .register-con-box .cta-2 h3 {
   	font-size: 13px;
   }
   article.virtual-workshop .register-con-box .cta-2 span {
   	font-size: 13px;
   }
   article.virtual-workshop .register-con-box .register-box {
   	padding: 50px 30px;
   }
}
@media (max-width: 767px) {
   article.virtual-workshop .register-con-box {
    padding: 40px 20px;
   }
   article.virtual-workshop .register-con-box h3 {
   	margin-bottom: 30px;
	margin-top: 20px;
   }
   article.virtual-workshop .register-con-box .cta-2 h3, article.virtual-workshop .register-con-box .cta-2 span {
   	text-align: center;
   	font-size: 18px;
   	line-height: 23px;
   }
   article.virtual-workshop .register-con-box .cta-2 {
   	margin-right: 0;
	margin-bottom: 50px;
   }
   article.virtual-workshop .register-con-box .cta-2 #arrow-right {
   	display: none;
   }
   article.virtual-workshop #topics {
   	padding: 50px 0;
   }
   article.virtual-workshop .register-con-box .register-box h2 {
   	font-size: 25px;
   }
   article.virtual-workshop .register-con-box .register-box a.register-btn {
   	font-size: 16px;
   }
   article.virtual-workshop #topics h3 {
   	font-size: 25px;
   }
   article.virtual-workshop .event-title {
   	font-size: 25px;
   	line-height: 35px;
   }
   article.virtual-workshop .intro-text {
   	font-size: 18px;
   }
   article.virtual-workshop .register-con-box h3 {
   	font-size: 20px;
   	line-height: 28px;
   }
   article.virtual-workshop .register-con-box .register-box #event-time {
   	font-size: 18px;
   }
   article.virtual-workshop #event-details p {
   	font-size: 18px;
   }
   article.virtual-workshop #topics .topic .topic-count {
   	font-size: 25px;
   }
   article.virtual-workshop #topics .topic p {
   	font-size: 18px;
   }
   article.virtual-workshop #cta-4 h4 {
   	font-size: 25px;
   }
   article.virtual-workshop #cta-4 p {
   	font-size: 18px;
   }
   article.virtual-workshop #cta-4 .register-btn {
   	font-size: 16px;
   }
   article.virtual-workshop .intro-text {
   	margin-bottom: 30px;
   }
   .rsvp-events header {
   	margin-bottom: 30px;
   }
   article.virtual-workshop .register-con-box .register-box {
   	padding: 30px 20px;
   }
   article.virtual-workshop #cta-4 {
    margin-top: 50px;
   }
}
@media (max-width: 600px) {
	article.virtual-workshop .register-con-box .cta-2 h3, article.virtual-workshop .register-con-box .cta-2 span {
   	text-align: center;
   	font-size: 16px;
   	line-height: 21px;
   }
   article.virtual-workshop #topics {
   	padding: 50px 0;
   }
   article.virtual-workshop .register-con-box .register-box h2 {
   	font-size: 23px;
   }
   article.virtual-workshop .register-con-box .register-box a.register-btn {
   	font-size: 16px;
   }
   article.virtual-workshop #topics h3 {
   	font-size: 23px;
   }
   article.virtual-workshop .event-title {
   	font-size: 23px;
   	line-height: 33px;
   }
   article.virtual-workshop .intro-text {
   	font-size: 16px;
   }
   article.virtual-workshop .register-con-box h3 {
   	font-size: 18px;
   	line-height: 25px;
   }
   article.virtual-workshop .register-con-box .register-box #event-time {
   	font-size: 16px;
   }
   article.virtual-workshop #event-details p {
   	font-size: 16px;
   }
   article.virtual-workshop #topics .topic .topic-count {
   	font-size: 23px;
   }
   article.virtual-workshop #topics .topic p {
   	font-size: 16px;
   }
   article.virtual-workshop #cta-4 h4 {
   	font-size: 23px;
   }
   article.virtual-workshop #cta-4 p {
   	font-size: 16px;
   }
}
@media (max-width: 360px) {
	article.virtual-workshop .event-title {
	    font-size: 22px;
	    line-height: 30px;
	}
}