body.login,
body.lostpassword{
	main {
	    width: 500px !important;
	    max-width: 90%;
    	margin: 0 auto;
    	float: none !important;
        padding-top: 15px;
    	padding-bottom: 15px;
    	background-color: #F9F9F9;
    	border-radius: 3px;

    	@media (min-width: 768px){
    		margin-top: 100px;
    	}

        @media (min-width: 1000px) and (max-height: 600px){
            margin-top: 0px;
        }

    	.page-header{
    		margin-top: 30px;
    		text-align: center;
    		border-bottom: 0px;
    		h1{
    			color: #3061A9;
    			margin-top: 10px;
    			font-size: 30px;
				font-weight: bold;
    		}
    	}

    	.tml-login,
        .tml-lostpassword{
    		margin: 0 auto;
    	}

    	input.input{
    		@include input_text;
    	}

    	ul.tml-action-links{
    		display: none;
    	}

    	#wp-submit{
    		@extend .btn-default;
			min-width: 100px;
			margin: 0 auto;
			display: block;
    	}

    	#loginform{
    		label {
    			display: none;
    		}
    	}

    	#rememberme{
    		margin-top: -2px;
    		& + label{
    			display: inline-block;
    			font-weight: normal;
    			visibility: hidden;
    			&.visible{
	    			visibility: visible;
	    		}	
    		}
    		
    	}

    	.tml-rememberme-submit-wrap {
    		display: block;
    		.tml-rememberme-wrap,
    		.tml-submit-wrap {
    			display: block;
    		}

    		.tml-rememberme-wrap{
    			margin-bottom: 15px;
    		}
    	}

		& + aside {
			display: none;
		}
	}
}