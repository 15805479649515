body{
    font-family: 'Muli', sans-serif;
}

// MixIns
@mixin input_text {
    border-radius: 5px;
    padding: 5px 10px;
    height: 40px;
    border: 1px solid #DDD;
}

// Global
.container {
    max-width: 100%;
    overflow: hidden;
}

html {
    height: 100%;
}

// Navigation Menu
.navbar-default {
    background-color: #232323;
    border-bottom: 0px;
    .navbar-brand,
    .navbar-nav li a {
        @media (min-width: 768px) {
            line-height: 80px;
            height: 80px;
            padding-top: 0;
        }
    }
    .navbar-brand {
        padding-bottom: 0px;
        padding-top: 0px;
        img {
            max-width: 100%;
            width: 300px;
            height: auto;
            margin-top: 10px;

            @media (max-width: 767px){
                width: 200px;
                height: auto;
                margin-top: 5px;
            }
        }
    }
    .navbar-nav {
        > li {
            > a {
                background-color: transparent !important;
                color: #FFF !important;
                font-size: 15px;
                font-weight: bold;
            }
        }
    }
}

// Homepage
body.home,
body.page-template-template-blurred-bg {
    min-height: 100%;
    background-image: url('/app/uploads/2016/10/blurred-bg-1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    .page-header {
        display: none;
    }
}

body.modal-open .navbar-container {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
}

.btn-default {
    border-radius: 5px;
    background-color: #3061A9;
    color: #FFF;
    padding: 10px 20px;
    min-width: 120px;
    max-width: 100%;
    font-size: 17px;
    border: 0px;
    &:hover,
    &:active {
        background-color: #254A82;
        color: #FFF;
    }
}

.hide-validation-errors {
    .validation_error {
        display: none;
    }
    li.gfield {
        border-bottom: 0px !important;
        border-top: 0px !important;
        background-color: transparent !important;
    }
    .gform_wrapper,
    .gform_heading,
    li.gfield:not(.gf_left_half):not(.gf_right_half) {
        width: 100% !important;
        max-width: 100% !important;
    }


    li.gfield_error {
        .ginput_complex,
        .gfield_description,
        .ginput_container {
            max-width: 100% !important;
            width: 100% !important;
            margin-bottom: 0px !important;
            margin-top: 0px !important;
            margin-left: 0px !important;
        }
    }
    li.gfield_error {
        margin-bottom: 0px !important;
        margin-top: 0px !important
    }
}

// Modal
.auto-modal {
    .modal-dialog {
        margin-top: 100px;
        @media (max-width: 767px){
        	margin-top: 20px;
        }
    }
    @extend .hide-validation-errors;
    .gform_title {
        text-align: center;
        font-size: 35px !important;
        font-weight: 700;
        @media (max-width: 600px) {
            font-size: 28px !important;
            br{
                display: none;
            }
        }
        @media (min-width: 600px) {
            line-height: 1.3;
        }

        &.gform_title-sm{
            font-size: 28px !important;
        }
    }
    .gform_description {
        text-align: center;
        padding: 20px 0;
        display: block;
        font-size: 20px;
        border-top: 1px solid #EEE;
        border-bottom: 1px solid #EEE;
        margin: 20px auto;
        @media (max-width: 600px) {
            font-size: 18px !important;
        }
    }
    li.gfield {
        input[type="text"] {
            @include input_text;
        }

        @media (max-width: 460px){
        	width: 100% !important;
        	input[type="text"] {
        		width: 100% !important;
        	}
        }
    }
    .gfield_label {
        font-size: 18px;
    }
    // Making stuff fullwidth
    .gform_wrapper,
    .gform_heading,
    li.gfield {
        width: 100% !important;
        max-width: 100% !important;
    }
    .gform_footer {
        text-align: center;
        margin-top: 0px;
        .gform_button {
            @extend .btn-default;
            font-size: 17px !important;
        }
    }
    .footer-msg {
        text-align: center;
        padding: 10px 10px 20px;
        font-size: 15px;
        a {
            color: #333 !important;
        }
    }
    .text-center {
        text-align: center;
        input {
            text-align: center;
        }
    }
    .no-label {
        label {
            line-height: 0 !important;
            height: 5px;
            display: block !important;
        }
    }

    .gform_wrapper .gfield_required{
        display: none;
    }
}

form.form-no-event {
    .gform_title {
        font-size: 32px !important;
    }
    .gfield_required {
        display: none;
    }
    span.name_first input + label,
    input + label {
        display: none !important;
    }
    .ginput_container_email {
        input {
            width: 97.5% !important;
        }
    }
    // Phone field and ZIP
    li#field_3_3,
    li#field_3_4 {
        width: 50% !important;
        float: left;
        clear: none;

        @media (max-width: 460px){
        	width: 100% !important;
        }

        input {
            width: 94.5%;
        }
        .ginput_right {
            width: 100%;
            margin-left: 0px;
        }
    }
    .ginput_container {
        margin-top: 0px !important;
    }
    .gform_footer {
        margin-top: 20px;
        display: inline-block;
        width: 100%;
    }
    .footer-msg {
        display: none;
    }
    .gform_body {
        width: 300px;
        margin: 0 auto;
        max-width: 100%;
    }
    .gfield_error {
        .gfield_label,
        .instruction.validation_message {
            display: none !important;
        }
    }
}

.events-list {
    margin: 30px 0;

    .event-zipcode-row {
        border-top:1px solid #8d8d8d;
        border-bottom:1px solid #8d8d8d;
        padding: 20px 0;
        margin-right: 15px;
        margin-left: 15px;

        .col-sm-9{
            padding-left: 0px;
            text-align: left;
        }
        .col-sm-3{
            padding-right: 0px;
            text-align: right;
        }
        h4{
            font-size: 16px;
            margin-bottom: 0;
            margin-top: 0;
        }
        span{
            font-size: 13px;
        }
        .button-event-link {
            border-radius: 5px;
            background-color: #3061a9;
            color: #fff;
            padding: 7px 20px;
            min-width: 105px;
            max-width: 100%;
            font-size: 15px;
            border: 0;
        }
    }
    .event-zipcode-row:last-child{
        border-top:none;
    }
}


// Hide validation erros default style for Register For The Event
form#gform_4{
    @extend .hide-validation-errors;
}

footer{
    background: #0B62A8;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #FFF;
}

body:not(.single) footer{
    display: none;
}

.navbar-default .navbar-toggle{
    display: none;
}

body.no-upcoming-events{
    .auto-modal .gform_title{
        font-size: 28px !important;
    }
}