.web-development-in-iloilo-pa-congress{
    .gform_wrapper{max-width: 100%;}
    @media (max-width: 767px){
        .event-google-map {
            margin-bottom: 50px;
        }
        .event-google-map div{width: 100% !important;}
        .event-google-map div iframe{width: 100% !important;}
        .event-place {
            width: 50%;
            float: left;
            margin-bottom: 50px;
        }
        .event-date {
            float: left;
            width: 50%;
        }
        .event-reasons li{
            display: inline-block;
            width: 33.333%;
        }
    }
    @media (max-width: 480px){
        .event-google-map {
            margin-bottom: 50px;
        }
        .event-google-map div{width: 100% !important;}
        .event-google-map div iframe{width: 100% !important;}
        .event-place {
            width: 100%;
            float: left;
            margin-bottom: 50px;
            text-align: center;
        }
        .event-date {
            float: left;
            width: 100%;
            margin-bottom: 25px;
            text-align: center;
        }
        .event-reasons li{
            display: inline-block;
            width: 100%;
        }
        .event-reasons ul.fa-ul{
            margin: 0px auto !important;
            width: 125px;
            text-align: left;

        }
        .event-reasons{
            text-align: center;
        }
        .event-info{
            text-align: center;
        }
        .event-reasons ul.fa-ul .fa-li{
            position: relative;
            left: 0px;
        }
        .rsvp-events header {
            margin-bottom: 30px;
        }
        .gform_wrapper .top_label li.gfield.gf_left_half {
            margin: 0px !important;
            width: 100%;
        }
        .gform_wrapper .top_label li.gfield.gf_right_half {
            margin: 0px !important;
            width: 100%;
        }
        .event-form .ginput_container input{
            width: 100% !important;
        }
    }
}